import Vue from "vue";

const url = "dashboard/loops";

export default {
  namespaced: true,

  state() {
    return {
      loopId: null
    };
  },

  actions: {
    async fetch(_ctx, { page, perPage, id, query }) {
      // id condition is to make this action reuseable for loop list and a single loop
      return await Vue.axios.get(
        `${url}${id ? `/${id}` : `?page=${page}&per_page=${perPage}&${query}`}`
      );
    },
    async create(_ctx, { payload }) {
      const { data } = await Vue.axios.post(url, payload);

      return data;
    },
    async update(_ctx, { payload, id }) {
      const { data } = await Vue.axios.put(`${url}/${id}`, payload);

      return data;
    },
    async delete(_ctx, id) {
      const { data } = await Vue.axios.delete(`${url}/${id}`);
      return data;
    },
    async assignOperator(_ctx, { id, operator_account_id }) {
      const { data } = await Vue.axios.post(`${url}/${id}/operator`, {
        operator_account_id
      });
      return data;
    },
    async deAssignOperator(_ctx, { id, operator_account_id }) {
      const { data } = await Vue.axios.delete(
        `${url}/${id}/operator/${operator_account_id}`
      );
      return data;
    },
    async capacity(_ctx, { id, page, perPage }) {
      return await Vue.axios.get(
        `${url}/${id}/capacity?page=${page}&per_page=${perPage}`
      );
    },
    async riders(_ctx, { id, page, perPage }) {
      return await Vue.axios.get(
        `${url}/${id}/commuters?page=${page}&per_page=${perPage}`
      );
    },
    async assignCommuter(_ctx, { id, commuter }) {
      const { data } = await Vue.axios.post(`${url}/${id}/commuters`, commuter);
      return data;
    },
    async deAssignCommuter(_ctx, { id, commuter_id }) {
      const { data } = await Vue.axios.delete(
        `${url}/${id}/commuters/${commuter_id}`
      );
      return data;
    },
    async bookings(_ctx, { id, page, perPage }) {
      return await Vue.axios.get(
        `dashboard/loop-bookings?loop_id=${id}&page=${page}&per_page=${perPage}`
      );
    },
    async operators(_ctx, { id, page, perPage }) {
      return await Vue.axios.get(
        `dashboard/loops/${id}/operator?&page=${page}&per_page=${perPage}`
      );
    }
  },

  mutations: {
    loopId(state, data) {
      state.loopId = data;
    }
  },

  getters: {
    loopId: ({ loopId }) => {
      return loopId;
    }
  }
};
