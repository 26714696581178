import Vue from "vue";

export default {
  namespaced: true,

  state() {
    return {};
  },

  actions: {
    fetch(data) {
      return Vue.auth.fetch(data);
    },

    refresh(data) {
      return Vue.auth.refresh(data);
    },

    async login(_ctx, data) {
      return await Vue.auth.login({
        url: "auth/login",
        data: data.body,
        staySignedIn: data.staySignedIn,
        fetchUser: true,
        redirect: "dashboard"
      });
    },

    logout() {
      return Vue.auth.logout();
    }
  },

  getters: {
    user() {
      return Vue.auth.user();
    }
  }
};
