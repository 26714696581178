import Vue from "vue";

const notification = {
  methods: {
    successToast(title) {
      this.$swal({
        toast: true,
        position: "bottom-end",
        showConfirmButton: false,
        timer: 3000,
        icon: "success",
        title
      });
    }
  }
};

Vue.mixin(notification);
