import Vue from "vue";

const url = "dashboard/charters";

export default {
  namespaced: true,

  state() {
    return {
      loopId: null
    };
  },

  actions: {
    async fetch(_ctx, { page, perPage, query }) {
      return await Vue.axios.get(
        `/dashboard/charters?page=${page}&per_page=${perPage}&${query}`
      );
    },
    async create(_ctx, { payload }) {
      const { data } = await Vue.axios.post(url, payload);

      return data;
    },
    async update(_ctx, { payload, id }) {
      const { data } = await Vue.axios.put(`dashboard/charters/${id}`, payload);

      return data;
    },
    async delete(_ctx, id) {
      const { data } = await Vue.axios.delete(`${url}/${id}`);
      return data;
    },
    async capacity(_ctx, { id, page, perPage }) {
      return await Vue.axios.get(
        `${url}/${id}/capacity?page=${page}&per_page=${perPage}`
      );
    },
    async riders(_ctx, { id, page, perPage }) {
      return await Vue.axios.get(
        `${url}/${id}/commuters?page=${page}&per_page=${perPage}`
      );
    },
    async bookings(_ctx, { id, page, perPage }) {
      return await Vue.axios.get(
        `dashboard/loop-bookings?loop_id=${id}&page=${page}&per_page=${perPage}`
      );
    }
  },

  mutations: {
    loopId(state, data) {
      state.loopId = data;
    }
  },

  getters: {
    loopId: ({ loopId }) => {
      return loopId;
    }
  }
};
