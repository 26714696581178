import Vue from "vue";
import * as Sentry from "@sentry/vue";

Sentry.init({
  Vue,
  dsn: process.env.VUE_APP_SENTRY_DSN,
  integrations: [
    Sentry.browserTracingIntegration({
      router: Vue.router
    }),
    Sentry.replayIntegration()
  ],
  logErrors: Vue.config.devtools,
  tracingOptions: {
    trackComponents: true
  },
  attachStacktrace: true,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 0.2,
  tracesSampleRate: Vue.config.devtools ? 0.5 : 0.2,
  environment: process.env.NODE_ENV,
  enabled: window.location.hostname !== "localhost"
});
