import Vue from "vue";

export default {
  namespaced: true,

  state() {
    return {};
  },

  actions: {
    // TODO: I fore a case where we might not need vuex, so this file might be deteleted in the future.
    async fetch(_ctx, { endpoint, query }) {
      return await Vue.axios.get(`dashboard/reports/${endpoint}?${query}`);
    }
  },

  mutations: {},

  getters: {}
};
