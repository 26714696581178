import Vue from "vue";

export default {
  namespaced: true,

  state() {
    return {};
  },

  actions: {
    async fetch(ctx, { page, perPage, query }) {
      const data = await Vue.axios.get(
        `/dashboard/users?page=${page}&per_page=${perPage}&${query}`
      );
      return data;
    },
    async fetchOne(ctx, { id }) {
      const { data } = await Vue.axios.get(`/dashboard/users/${id}`);
      return data;
    },
    async updateZonalPricing(ctx, payload) {
      const { data } = await Vue.axios.put(
        `/dashboard/zonal-pricing/${payload.id}`,
        payload
      );
      return data;
    },
    async fetchCharterPricing() {
      const { data } = await Vue.axios.get("/dashboard/vehicle/pricings");
      return data;
    },
    async updateCharterPricing(ctx, payload) {
      const { data } = await Vue.axios.put(
        `/dashboard/vehicle/pricings/${payload.id}`,
        payload
      );
      return data;
    },
    async deleteUserRole(ctx, userId) {
      const { data } = await Vue.axios.post(
        `/dashboard/userable/unlink/${userId}`,
        { delete: true }
      );
      return data;
    },
    // save user
    async save(ctx, payload) {
      const { data } = await Vue.axios.post("/dashboard/users", payload);
      return data;
    },
    async update(ctx, { payload, id }) {
      const { data } = await Vue.axios.put(`/dashboard/users/${id}`, payload);
      return data;
    },
    async createCommuterAccount(ctx, userId) {
      const { data } = await Vue.axios.post(
        `/dashboard/users/${userId}/commuter`
      );
      return data;
    }
  },

  mutations: {},

  getters: {}
};
