import Vue from "vue";

export default {
  namespaced: true,

  state() {
    return {};
  },

  actions: {
    async statistics() {
      return await Vue.axios
        .get("dashboard/statistics")
        .then(({ data }) => data)
        .catch(error => error);
    },
    async export(_ctx, { query, service }) {
      const { data } = await Vue.axios.get(
        `dashboard/${service}/export?${query}`,
        { responseType: "blob" }
      );
      return data;
    }
  }
};
