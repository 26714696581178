import { initializeApp } from "firebase/app";

initializeApp({
  projectId: "allinone-c0f8b",
  appId: "1:510229461017:web:5a545e251ba79232f41288",
  databaseURL: "https://allinone-c0f8b.firebaseio.com",
  storageBucket: "allinone-c0f8b.appspot.com",
  apiKey: "AIzaSyDVLfpP209g-2_Rpz9SCIPMtVfLhDpsjQg",
  authDomain: "allinone-c0f8b.firebaseapp.com",
  messagingSenderId: "510229461017",
  measurementId: "G-4J052Y63M3"
});
