import Vue from "vue";

import VueSweetalert2 from "vue-sweetalert2";

import "sweetalert2/dist/sweetalert2.min.css";

const options = {
  allowOutsideClick: false,
  customClass: {
    confirmButton: "bg-dark",
    denyButton: "bg-danger",
    actions: "px-2"
  }
};
Vue.use(VueSweetalert2, options);
