<template>
  <nav class="main-header navbar navbar-expand navbar-light bg-white">
    <!-- Left navbar links -->
    <ul class="navbar-nav">
      <li class="nav-item">
        <a
          class="nav-link"
          data-widget="pushmenu"
          href="#"
          role="button"
          @click="toggle()"
        >
          <b-icon icon="layout-sidebar-inset"></b-icon
        ></a>
      </li>
    </ul>

    <ul class="navbar-nav ml-auto">
      <b-nav-item-dropdown
        :text="fullName"
        toggle-class="text-dark font-weight-bold text-capitalize"
      >
        <b-dropdown-item to="/profile">
          <b-icon icon="person-square"></b-icon> Profile
        </b-dropdown-item>
        <b-dropdown-item href="#" @click="logout">
          <b-icon icon="box-arrow-right"></b-icon> Logout
        </b-dropdown-item>
      </b-nav-item-dropdown>

      <!-- <b-avatar variant="warning" badge badge-variant="success"></b-avatar> -->
    </ul>
  </nav>
</template>

<script>
import pushMenu from "@/mixins/PushMenu";
export default {
  mixins: [pushMenu],
  computed: {
    fullName() {
      return this.$auth.check()
        ? this.$auth.user().name + " " + this.$auth.user().surname
        : "";
    },
    avatar() {
      return this.$auth.check()
        ? this.$auth.user().picture
        : "@/assets/img/logo.png";
    }
  },
  methods: {
    logout() {
      this.$auth.logout();
    }
  }
};
</script>
