import Vue from "vue";

export default {
  namespaced: true,

  state() {
    return {};
  },

  actions: {
    async fetch(_ctx, { page = 1, perPage = 5 }) {
      const data = await Vue.axios.get(
        `/dashboard/driver/driver-broadcast?page=${page}&per_page=${perPage}`
      );
      return data;
    },
    async fetchCharterPricing() {
      const { data } = await Vue.axios.get("/dashboard/vehicle/pricings");
      return data;
    },
    async create(_ctx, payload) {
      const { data } = await Vue.axios.post(
        "/dashboard/driver/driver-broadcast",
        payload
      );

      return data;
    }
  },

  mutations: {},

  getters: {}
};
