import Vue from "vue";
import { LMap, LTileLayer, LMarker, LPopup } from "vue2-leaflet";
import Vue2LeafletMarkerCluster from "vue2-leaflet-markercluster";

import "beautifymarker/leaflet-beautify-marker-icon";
import "leaflet/dist/leaflet.css";
import "beautifymarker/leaflet-beautify-marker-icon.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "leaflet.markercluster/dist/MarkerCluster.css";
import "leaflet.markercluster/dist/MarkerCluster.Default.css";

Vue.component("l-map", LMap);
Vue.component("l-tile-layer", LTileLayer);
Vue.component("l-marker", LMarker);
Vue.component("l-popup", LPopup);
Vue.component("l-marker-cluster", Vue2LeafletMarkerCluster);
