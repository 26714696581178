import Vue from "vue";

const url = "dashboard/vehicles";

export default {
  namespaced: true,

  state() {
    return {};
  },

  actions: {
    async fetch(_ctx, { page, perPage, query }) {
      return await Vue.axios.get(
        `${url}?page=${page}&per_page=${perPage}&${query}`
      );
    },
    async create(_ctx, { payload }) {
      const { data } = await Vue.axios.post(url, payload);

      return data;
    },
    async update(_ctx, { payload, vehicle }) {
      const { data } = await Vue.axios.put(`${url}/${vehicle.id}`, payload);

      return data;
    },
    async delete(_ctx, id) {
      const { data } = await Vue.axios.delete(`${url}/${id}`);
      return data;
    }
  },

  getters: {}
};
