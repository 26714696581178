<template>
  <aside class="main-sidebar sidebar-light-dark border-right">
    <!-- Brand Logo -->
    <a href="index3.html" class="brand-link">
      <img src="@/assets/logo.png" class="brand-image mx-auto" />
    </a>

    <!-- Sidebar -->
    <div class="sidebar">
      <!-- Sidebar Menu -->
      <nav class="mt-2">
        <b-nav pills class="nav-sidebar flex-column nav-child-indent">
          <li
            class="nav-item"
            :class="menuItem.id !== open ? '' : 'menu-open'"
            v-for="menuItem in menu"
            :key="menuItem.id"
          >
            <b-link
              class="nav-link"
              exact-active-class="active"
              @click="toggle(menuItem.id)"
              :to="menuItem.path"
            >
              <b-icon :icon="menuItem.icon" class="nav-icon"></b-icon>
              <p>
                {{ menuItem.label }}
                <b-icon
                  v-if="menuItem.children"
                  :icon="menuItem.id == open ? 'chevron-down' : 'chevron-right'"
                  class="right"
                  font-scale="1"
                ></b-icon>
              </p>
            </b-link>
            <b-nav class="nav-treeview" v-show="menuItem.id == open">
              <b-nav-item
                v-for="(item, i) in menuItem.children"
                :key="i"
                exact-active-class="active"
                :to="item.path"
              >
                <p>
                  {{ item.label }}
                </p>
              </b-nav-item>
            </b-nav>
          </li>
        </b-nav>
      </nav>
      <!-- /.sidebar-menu -->
    </div>
    <!-- /.sidebar -->
  </aside>
</template>

<script>
export default {
  name: "Sidebar",
  props: ["menu"],
  data: () => ({
    open: null
  }),
  methods: {
    toggle(id) {
      this.open = this.open == id ? null : id;
    }
  }
};
</script>

<style lang="scss">
.nav-link .b-icon.bi {
  vertical-align: text-top !important;
}
</style>
