<template>
  <component :is="layout">
    <router-view />
    <v-idle v-if="loggedIn" v-show="showTimer" @idle="onIdle" :duration="840" />
  </component>
</template>

<script>
import { setUser, feedbackIntegration } from "@sentry/vue";
import { getAuth, signInWithCustomToken } from "firebase/auth";

const plain = "plain";

export default {
  mounted() {
    this.auth = getAuth();
  },
  data() {
    return {
      feedbackWidget: null,
      auth: null,
      showTimer: false
    };
  },
  computed: {
    layout() {
      return (this.$route.meta.layout || plain) + "-layout";
    },
    loggedIn() {
      return this.$auth.check();
    }
  },
  methods: {
    async fbCheck() {
      await this.auth.authStateReady();
      return this.auth.currentUser;
    },
    async getFbToken() {
      const { data } = await this.axios.get("auth/firebase-token");
      return data?.token;
    },
    async signInWithCustomToken() {
      const user = await this.fbCheck();
      if (!user) {
        const token = await this.getFbToken();
        if (token) {
          signInWithCustomToken(this.auth, token);
        }
      }
    },
    onIdle() {
      let timerInterval;
      this.$swal
        .fire({
          icon: "info",
          title: "Auto close alert!",
          html: "Your session is about to time out in <b></b> seconds, after which you will be logged out.",
          timer: 62000,
          timerProgressBar: true,
          showCancelButton: true,
          confirmButtonText: "Logout",
          didOpen: () => {
            const b = this.$swal.getHtmlContainer().querySelector("b");
            timerInterval = setInterval(() => {
              b.textContent = (this.$swal.getTimerLeft() / 1000) | 0;
            }, 1000);
          },
          willClose: () => {
            clearInterval(timerInterval);
          }
        })
        .then(result => {
          if (
            result.dismiss === this.$swal.DismissReason.timer ||
            result.isConfirmed
          ) {
            this.$store.dispatch("auth/logout");
          }

          if (result.dismiss === this.$swal.DismissReason.timer) {
            this.$swal.fire({
              icon: "info",
              text: "You have been logged out because you left the session idle for 15 minutes."
            });
          }
        });
    }
  },
  watch: {
    loggedIn(signedIn) {
      if (signedIn) {
        const user = this.$auth.user();
        setUser({
          id: user.id,
          email: user.email,
          username: user.name + " " + user.surname
        });
        this.signInWithCustomToken();

        const feedback = feedbackIntegration({
          colorScheme: "light",
          autoInject: false,
          showName: false,
          showEmail: false,
          showBranding: false
        });

        this.feedbackWidget = feedback?.createWidget();
        this.feedbackWidget?.appendToDom();
      } else {
        const user = this.fbCheck();
        if (user) {
          this.auth.signOut();
        }
        this.feedbackWidget?.removeFromDom();
      }
    }
  }
};
</script>
