import Vue from "vue";

export default {
  namespaced: true,

  state() {
    return {};
  },

  actions: {
    async fetch(_ctx, { page, perPage, query }) {
      return await Vue.axios.get(
        `dashboard/commuter?page=${page}&per_page=${perPage}&${query}`
      );
    },
    async export(_ctx, { query }) {
      const { data } = await Vue.axios.get(
        `dashboard/commuter/account/export?${query}`,
        { responseType: "blob" }
      );
      return data;
    },
    async update(_ctx, { payload, id }) {
      const { data } = await Vue.axios.put(`dashboard/commuter/${id}`, payload);
      return data;
    }
  }
};
