import Vue from "vue";

const url = "dashboard/driver";

export default {
  namespaced: true,

  state() {
    return {};
  },

  actions: {
    async fetch(_ctx, { page = 1, perPage = 5, query }) {
      const data = await Vue.axios.get(
        `${url}?page=${page}&per_page=${perPage}&${query}`
      );

      return data;
    },
    async create(_ctx, { payload }) {
      const { data } = await Vue.axios.post(url, payload);

      return data;
    },
    async update(_ctx, { payload, driver }) {
      const { data } = await Vue.axios.put(`${url}/${driver.id}`, payload);

      return data;
    },
    async delete(_ctx, id) {
      return await Vue.axios.delete(`${url}/${id}`);
    },
    async assignDriverToVehicle(_ctx, { driver_account_id, id }) {
      const { data } = await Vue.axios.put(`dashboard/vehicles/${id}`, {
        driver_account_id
      });

      return data;
    },
    async assignDriverToTrip(_ctx, { id, driver_account_id }) {
      const { data } = await Vue.axios.put(`dashboard/trips/${id}/driver`, {
        driver_account_id
      });

      return data;
    },
    async export(_ctx, { query }) {
      const { data } = await Vue.axios.get(`${url}/export?${query}`, {
        responseType: "blob"
      });
      return data;
    }
  },

  getters: {}
};
