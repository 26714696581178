import Vue from "vue";

export default {
  namespaced: true,

  state() {
    return {
      loopId: null
    };
  },

  actions: {
    async fetch(_ctx, { loopId, page, perPage }) {
      return await Vue.axios.get(
        `dashboard/loops/${loopId}/schedules?page=${page}&per_page=${perPage}`
      );
    },
    async create(_ctx, payload) {
      const { data } = await Vue.axios.post("dashboard/trips", payload);

      return data;
    },
    async delete(_ctx, id) {
      const { data } = await Vue.axios.delete(`dashboard/trips/${id}`);
      return data;
    },
    async bookings(_ctx, { id, page, perPage }) {
      return await Vue.axios.get(
        `dashboard/loop-bookings?loop_id=${id}&page=${page}&per_page=${perPage}`
      );
    },
    async trips(_ctx, { scheduleId }) {
      return await Vue.axios.get(`dashboard/trips?schedule_id=${scheduleId}`);
    },
    async info(_ctx, { loopId, scheduleId }) {
      return await Vue.axios.get(
        `dashboard/loops/${loopId}/schedules/${scheduleId}`
      );
    }
  },

  mutations: {
    loopId(state, data) {
      state.loopId = data;
    }
  },

  getters: {
    loopId: ({ loopId }) => {
      return loopId;
    }
  }
};
