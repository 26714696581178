import Vue from "vue";

const notification = {
  methods: {
    validateState(form, field) {
      const { $dirty, $error } = form[field];
      return $dirty ? !$error : null;
    }
  }
};

Vue.mixin(notification);
