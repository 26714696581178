import "./axios";
import "./bootstrap-vue";
import "./vue-auth";
import "./vuelidate";
import "./sweatalert";
import "./vue-file-upload";
import "./vue-phone-number-input";
import "./vue-multiselect";
import "./vue-leaflet";
import "./v-idle";
import "./sentry";
import "./firebase";
import "./vue-moment";

export default {};
