import Vue from "vue";

const url = "dashboard/operator";

export default {
  namespaced: true,

  state() {
    return {};
  },

  actions: {
    async fetch(_ctx, { page = 1, perPage = 5, query }) {
      const data = await Vue.axios.get(
        `${url}?page=${page}&per_page=${perPage}&${query}`
      );

      return data;
    },
    async fetchOne(_ctx, { id }) {
      return await Vue.axios
        .get(`${url}/${id}&page=1&per_page=10`)
        .then(data => data)
        .catch(error => error);
    },
    async create(_ctx, { payload }) {
      const { data } = await Vue.axios.post(url, payload);

      return data;
    },
    async update(_ctx, { payload, id }) {
      const { data } = await Vue.axios.put(`${url}/${id}`, payload);

      return data;
    },
    async delete(_ctx, id) {
      return await Vue.axios.delete(`${url}/${id}`);
    },
    async organizations(_ctx, payload) {
      return await Vue.axios.get(
        `dashboard/operator-organizations?${new URLSearchParams(
          payload
        ).toString()}`
      );
    },
    async userAccess() {
      return await Vue.axios
        .get(`${url}/user/access`)
        .then(({ data }) => data)
        .catch(error => error);
    },
    async loopPricing() {
      return await Vue.axios
        .get("dashboard/loop-pricing")
        .then(({ data }) => data)
        .catch(error => error);
    },
    async availableOperatorOrganizations() {
      return await Vue.axios
        .get(`dashboard/operator-organizations/available`)
        .then(({ data }) => data)
        .catch(error => error);
    },
    async createOperatorOrganization(_ctx, payload) {
      const { data } = await Vue.axios.post(
        "/dashboard/operator-organizations",
        payload
      );

      return data;
    },
    async deleteOperatorOrganization(_ctx, { id }) {
      return await Vue.axios.delete(`/dashboard/operator-organizations/${id}`);
    }
  },

  getters: {}
};
